@import '@angular/material/theming';
@include mat-core();

/* -------------------------- PERSONALIZADO http://mcg.mbitson.com -------------------------- */
$black: (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$orange: (
  50 : #ffede1,
  100 : #ffd1b3,
  200 : #ffb381,
  300 : #ff944e,
  400 : #ff7d28,
  500 : #ff6602,
  600 : #ff5e02,
  700 : #ff5301,
  800 : #ff4901,
  900 : #ff3801,
  A100 : #ffffff,
  A200 : #fff4f2,
  A400 : #ffc9bf,
  A700 : #ffb4a6,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$bv-palette-primary: mat-palette($black);
$bv-palette-accent:  mat-palette($orange);
$bv-theme: mat-light-theme($bv-palette-primary, $bv-palette-accent);
@include angular-material-theme($bv-theme);
